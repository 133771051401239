.product-image {
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.product-item {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.product-item:active {
  background-color: rgb(255, 240, 240);
}

.product-item h2 {
  font-size: 16px;
  color: var(--black);
  font-weight: 700;
}

.product-item-text {
  font-size: 12px;
  color: var(--gray);
  font-weight: 400;
  margin-top: 6px;
}

.product-offer-price {
  font-size: 16px;
  color: var(--black);
  font-weight: 800;
}

.product-offer-strike {
  font-size: 12px;
  color: var(--gray);
  font-weight: 400;
  margin-right: 6px;
  text-decoration: line-through;
}

.add-to-cart-btn {
  color: var(--theme);
  border: 1px solid var(--theme);
}

.add-to-cart-btn:active {
  color: var(--white);
  border-color: var(--theme) !important;
  background-color: var(--theme);
}

.add-to-cart-btn:hover {
  color: var(--white) !important;
  border-color: var(--theme) !important;
  background-color: var(--theme) !important;
}
