.search-tag-container {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: nowrap;
}

.search-tag-container .ant-tag-checkable-checked {
  background-color: var(--theme) !important;
  border-radius: 16px;
  font-size: 12px;
}

.search-tag-container .ant-tag {
  font-size: 12px;
}

.search-tag-container .ant-tag:hover {
  color: var(--white);
}

.search-tag-container .ant-tag-checkable {
  padding: 6px 12px;
  background-color: #d9d9d9;
  border-radius: 16px;
  font-size: 12px;
}

.product-list-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 6px;
  width: 100%;
}

/* @media (max-width: 375px) {
  .search-tag-container {
    gap: 5px;
    flex-wrap: wrap;
    justify-content: start;
  }
} */
