.cart-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.cart-details p {
  font-size: 16px;
  font-weight: 600;
  width: 80%;
}

.cart-controller {
  display: flex;
  align-items: center;
  gap: 6px;
}
