.profile-user {
  font-size: 20px;
  color: var(--black);
  font-weight: 800;
}

.profile-phone {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
  margin-top: 6px;
}

.profile-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 20px;
}

.profile-link-title {
  font-size: 16px;
  font-weight: 800;
  color: var(--black);
}

.profile-link-container {
  padding: 10px;
}

.profile-link-container:active {
  background-color: rgb(255, 240, 240);
}

.toggle-features-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
