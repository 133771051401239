.ant-input::placeholder {
  color: var(--gray);
  font-weight: 500;
  font-size: 16px;
}

.ant-checkbox .ant-checkbox-inner {
  height: 20px !important;
  width: 20px !important;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: var(--black) !important;
}

.ant-input-outlined:hover {
  border: 1px solid var(--black);
  outline: none !important;
  box-shadow: none !important;
}

.ant-input-outlined:focus {
  border: 1px solid var(--black);
  outline: none !important;
  box-shadow: none !important;
}

.ant-select-selection-placeholder {
  color: var(--gray) !important;
  font-size: 16px;
}

.ant-select-selector {
  border-radius: 10px !important;
  border: none !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--theme) !important;
  border-color: var(--theme) !important;
}

.ant-message {
  bottom: 60px !important;
  top: initial !important;
}

.ant-select-selection-item {
  font-size: 14px !important;
}

.ant-switch.ant-switch-checked {
  background: var(--black) !important;
}
