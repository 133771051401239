.home-container {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.services-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 600px;
  height: auto;
}
