:root {
  --theme: #ee3f37;
  --black: #121212;
  --green: #1acd81;
  --white: #ffffff;
  --offwhite: #f5f5f5;
  --gray: #5b5b5b;
  --yellow: #f5e653;
  --blue: #1a62cd;
}

* {
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
  background-color: #f5f5f5;
}

p {
  font-size: 16px;
  color: var(--black);
}

a {
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: var(--black);
}

.main-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-view {
  width: 33rem;
  height: 100vh;
}
