.scan-title {
  font-size: 20px;
  color: var(--theme);
  font-weight: 800;
}

.scan-desc {
  font-size: 16px;
  color: var(--black);
  font-weight: 400;
  margin-top: 10px;
}
