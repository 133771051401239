.referral-container {
  padding-bottom: 60px;
  padding-top: 20px;
}

.curve-container {
  margin-top: 60px;
}

.referral-container h3 {
  font-size: 18px;
  color: var(--black);
  font-weight: 600;
}

.referral-container h1 {
  font-size: 20px;
  color: var(--black);
  font-weight: 600;
  padding: 10px 30px;
  text-align: center;
}

.referral-container-p {
  font-size: 16px;
  color: var(--gray);
  font-weight: 400;
  text-align: center;
  padding: 10px 30px;
}

.common-small-p {
  font-size: 14px;
  color: var(--gray);
}
