.buttonBlack-style {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  background-color: var(--black);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: none !important;
  text-transform: uppercase;
}

.buttonBlack-style:hover {
  background: var(--black) !important;
  color: var(--white) !important;
  border-color: none !important;
}
