.quote-container {
  margin: 10px 0;
}

.quote-container h4 {
  color: #939393;
  font-size: 45px;
  font-weight: 900;
}

.quote-container p {
  color: #b6b6b6;
  font-size: 15px;
  font-weight: 300;
}
