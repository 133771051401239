.mobile-container {
  background-color: #f5f5f5;
  padding: 0 20px;
  /* height: 100%; */
  padding-bottom: 100px;
}

.navigation-container {
  background-color: var(--white);
}

/* Responsive adjustments */
@media (max-width: 390px) {
  .mobile-container {
    padding-bottom: 220px;
  }
}

@media (max-width: 414px) {
  .mobile-container {
    padding-bottom: 200px;
  }
}

@media (max-width: 375px) {
  .mobile-container {
    padding-bottom: 200px;
  }
}

@media (max-width: 365px) {
  .mobile-container {
    padding-bottom: 200px;
  }
}
