.invoice {
  margin: auto;
}

.line-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 16px;
}

.line-item.total {
  font-weight: bold;
  font-size: 18px;
}
