.radio-style {
  height: 50px;
  background-color: var(--white);
  border: 1px solid var(--black);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
