.bottom-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--white);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  height: 40px;
}
