.search-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.search-container h4 {
  font-size: 18px;
  color: var(--black);
  font-weight: 700;
}

.search-container p {
  font-size: 14px;
  color: var(--gray);
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  line-height: 1.5em;
}

.search-container:active {
  background-color: rgb(255, 240, 240);
}

.search-no-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.search-no-result p {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}
