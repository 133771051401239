.common-title {
  font-weight: bolder;
  font-size: 23px;
}

.sp-flex {
  display: flex;
}

.sp-flex-justify-centered {
  display: flex;
  justify-content: center;
}

.sp-flex-align-centered {
  display: flex;
  align-items: center;
}

.sp-flex-align-justify-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp-flex-align-justify-between {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.page-heading {
  font-size: 35px;
  color: var(--black);
  font-weight: 900;
}

.span-red {
  color: var(--theme);
}

.card-container {
  border-radius: 10px;
  background-color: var(--white);
  padding: 16px;
}

.common-p {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
}

.card-title {
  font-size: 16px;
  color: var(--gray);
  font-weight: 500;
}
