.detail-top-container {
  display: flex;
  justify-content: space-between;
}

.detail-title {
  color: var(--black);
  font-size: 23px;
  font-weight: bold;
}

.detail-price {
  color: var(--theme);
  font-size: 23px;
  font-weight: bold;
}

.desc-container {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.desc-title {
  font-size: 18px;
  color: var(--black);
  font-weight: 500;
  margin-bottom: 10px;
}

.desc-desc {
  font-size: 16px;
  color: var(--gray);
  font-weight: 400;
}

.ant-collapse-header-text {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
  margin-bottom: 10px;
}

.floating-button {
  position: fixed;
  z-index: 1;
  bottom: 80px;
  width: 90%;
}
