.card-1 {
  background-color: #c0f6bf;
  border-radius: 10px;
  padding-left: 30px;
  height: 140px;
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.card-2 {
  background-color: #bfe9f6;
  border-radius: 10px;
  padding-left: 30px;
  height: 140px;
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.offer-slide-p {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
}

.offer-slide-h3 {
  font-size: 50px;
  color: var(--black);
  font-weight: 800;
}

.offer-slide-button {
  background-color: var(--white);
  color: var(--black);
  font-weight: 600;
  border-radius: 16px;
  height: 30px;
  border: none;
  width: 100px;
}
