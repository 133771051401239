.box {
  box-sizing: border-box;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.box:active {
  background-color: rgb(255, 240, 240);
}

.box h3 {
  font-size: 16px;
  color: var(--black);
  font-weight: 800;
}

.box ol {
  font-size: 10px;
  color: var(--black);
  font-weight: 600;
  list-style: circle;
  padding-left: 15px;
}

.box li {
  margin-bottom: 5px;
}
